import React from 'react'
import {useEnvironment, withTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ROOT_COMPONENT_ID} from '../constants'
import {isEmptyStateVisible, isNoResultsStateVisible} from './selectors/schedule'
import {WidgetPropsContext} from './hooks/widget-props'
import {Filters} from './components/filters'
import {Schedule} from './components/schedule'
import {Header} from './components/header'
import {classes} from './widget.st.css'
import {EmptyState} from './components/empty-state'
import {WidgetProps} from './interfaces'
import {Modals} from './components/modals'
import {NoResults} from './components/no-results'
import {Button} from './components/button'
import {ItemDivider} from './components/schedule/divider'

export default withTranslation()((props: WidgetProps) => {
  const {t, resetFilter, eventDetailsUrl, navigate, days} = props
  const {isViewer, isMobile} = useEnvironment()
  const emptyStateVisible = isEmptyStateVisible(props)

  return (
    <WidgetPropsContext.Provider value={props}>
      <div
        id={ROOT_COMPONENT_ID}
        className={classNames(classes.root, classes.container, {[classes.mobile]: isMobile})}
        data-hook={DH.ROOT_NODE}
      >
        {emptyStateVisible ? (
          <EmptyState />
        ) : (
          <div className={classes.content}>
            <Header />
            <Filters />
            {isNoResultsStateVisible(props) ? (
              <NoResults onLinkClick={resetFilter} t={t} />
            ) : (
              <>
                <Schedule days={days} />
                {isMobile && (
                  <>
                    <ItemDivider />
                    <div className={classes.buttonContainer}>
                      <Button
                        fullWidth={true}
                        eventDetailsUrl={eventDetailsUrl}
                        navigate={navigate}
                        isSite={isViewer}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {emptyStateVisible ? null : <Modals />}
    </WidgetPropsContext.Provider>
  )
})
